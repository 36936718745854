import React, { Component } from 'react';

import Menu from './Menu'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Perspectivas extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div className="degradado_tres">
        <Menu ver={1}/>
        <div className="fondo_3"><div className="fondo_oscuro padding_menu">
          <div className="max_width padding_width_grande">
            <center><h9>{localStorage.idioma ? 'Industry Insights' : 'Perspectivas en la Industria'}</h9></center>
            <div id="cl"><br/><br/></div>
            {
              window._my_perspectivas.slice(4).map((item, i) => 
                <div className="perspectivas_all">
                  <h17>{localStorage.idioma ? item.nombreIN : item.nombre}</h17>
                  <div id="cl"><br/></div>
                  <div className="at_j">
                    {
                      localStorage.idioma ? 
                        item.descripcionIN
                      :
                        item.descripcion
                    }
                  </div>
                  <div id="cl"></div>
                  <a href={item.link} target="new" className="de_nueva"><div className="nueva" title="Ver más">+</div></a>
                  <div id="cl"></div>
                </div>
              )
            }
            <div id="cl"></div>
          </div>
        </div></div>
      </div>
    )
  }
}

export default Perspectivas;
import React, { Component } from 'react';

import Menu from './Menu'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:'',
      correo:'',
      telefono:'',
      mensaje:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleClick(e) {
    const {nombre, correo, mensaje} = this.state;
    if(nombre===''){
      window.alerta('error', localStorage.idioma ? 'Enter your full name' : 'Ingresa tu nombre completo');
    }else if(correo===''){
      window.alerta('error', localStorage.idioma ? 'Enter your email' : 'Ingresa tu correo electrónico');
    }else if(window.validarMail(correo)===false){
      window.alerta('error', localStorage.idioma ? 'Invalid email' : 'Ingrese un correo electrónico valido');
    }else if(mensaje===''){
      window.alerta('error', localStorage.idioma ? 'Write a message' : 'Escribe un mensaje');
    }else{
      window.window.H5_loading.show();
      let data = new FormData(document.getElementById('formulario'));
      fetch(window.envia, {
      //fetch('http://localhost/examen/wsCorreoDigital.php', {
         method: 'POST',
         body: data
      })
        .then(function(response) {
          if(response.ok===true){
            window.alerta('success', localStorage.idioma ? 'Mail sent successfully' : 'El mensaje ha sido enviado de manera satisfactoria, pronto nos pondremos en contacto');
          }else{
            window.alerta('error', localStorage.idioma ? 'Error, try again later' : 'Ha ocurrido un error intentalo más tarde');
          }
        })
        .then(function(texto) {
           window.H5_loading.hide();
        })
        .catch(err => console.error(err));
        this.setState({nombre:'',correo:'',mensaje:'',telefono:''});
    }
  }

  render () {
    return (
      <div className="degradado_tres padding_menu">
        <Menu ver={1}/>
        <div className="max_width padding_width">
          <div className="text_media infcontacto" data-aos="fade-right">
            <h4>{localStorage.idioma ? 'Come with us' : 'Acércate con nosotros'}</h4><br/>
            {/*<h16>¿Tienes algún proyecto en mente? Escríbenos y cuéntanos como podemos ayudarte.</h16>
            <br/><br/>*/}
            <ul className="listDos">
              <li>{localStorage.idioma ? <div>Because we develop based on your needs and we know the importance of your investment.</div> : <div>Sabemos la importancia de tu inversión y <b>desarrollamos en base a tus necesidades.</b></div>}</li>
              <li>{localStorage.idioma ? <div>Because our services are focused on 100% functionality.</div> : <div>Nuestros servicios están enfocados en la <b>funcionalidad al 100%.</b></div>}</li>
              <li>{localStorage.idioma ? <div>Because the objectives are aimed at having <b>more and better customers.</b></div> : <div>Los objetivos están dirigidos a que tengas <b>más y mejores clientes.</b></div>}</li>
            </ul>
            <br/>
            <h4>{localStorage.idioma ? 'Contact information' : 'Información de contacto'}</h4>
            <ul className="list1">
              {/*<li className="telefono">{window.telefono}</li>*/}
              <li className="correo"><a href={`mailto:${window.correo}`}>{window.correo}</a></li>
              <li className="ubicacion">{window.ubicame}</li>
            </ul>
            {
              window._my_redes.length>0 ?
                <div>
                  <h4>{localStorage.idioma ? 'Social networks' : 'Redes sociales'}</h4>
                  <div className="redes">
                    {
                      window._my_redes.map((item, i) =>
                        <a href={item.link} target="new"><img src={item.img} alt={item.nombre} title={item.nombre} className="grises"/></a>
                      )
                    }
                  </div>
                </div>
              :
                null
            }
          </div>
          <div className="text_media infoform mar_l40" data-aos="fade-left">
            <a href={window.whats} target="new" className="con_whats">
              <div className="con_wa"><img src="img/r_whats-01.svg" alt="Whatsapp" title="Whatsapp"/></div>
              {localStorage.idioma ? <div>Send us a message on WhatsApp</div> : <div>Escribenos por WhatsApp</div>}  
            </a>
            <form id="formulario">
              <input type="text" name="nombre" onChange={this.handleChange} placeholder={localStorage.idioma ? 'Name:' : 'Nombre:'} value={this.state.nombre}/>
              <input type="text" name="correo" onChange={this.handleChange} placeholder="e-mail:" value={this.state.correo}/>
              <input type="text" name="telefono" onChange={this.handleChange} placeholder={localStorage.idioma ? 'Phone number:' : 'Teléfono:'} value={this.state.telefono}/>

              <textarea name="mensaje" id="area" onChange={this.handleChange} placeholder={localStorage.idioma ? "Message:" : "Mensaje:"} value={this.state.mensaje}/>                  
              <center><div onClick={this.handleClick} className="button puntero">{localStorage.idioma ? 'SEND' : 'ENVIAR'}</div></center>
            </form>
          </div>
          <div id="cl"><br/><br/><br/></div>
          <iframe data-aos="fade-up" src={window.mapa} className="mapa" title="Mapa" allowfullscreen/>
          <div id="cl"><br/></div>
        </div>
      </div>
    )
  }
}

export default Contacto;
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Element, Link as Ir } from 'react-scroll';
import { connect } from 'react-redux';
import { Accordion, AccordionItem } from 'react-sanfona';

import Es from './../redux/actionEs';
import In from './../redux/actionIn';

class Menu extends Component {
  constructor() {
    super()
    this.state = {
      verMenu: 'ver_no',
      verIco: 'menu.svg',
      lenguaje: 'ES',
      scroll: 0,
    }
    this.verMenu = this.verMenu.bind(this)
  }

  activa(e) {
    this.setState({verMenu: 'ver_no', verIco: 'menu.svg'})
  }

  verMenu() {
    if(this.state.verMenu==='ver_no'){
      this.setState({verMenu: 'ver_si', verIco: 'menu_x.svg'})
    }else{
      this.setState({verMenu: 'ver_no', verIco: 'menu.svg'})
    }
  }

  componentWillMount () {
    window.addEventListener("scroll", (e) => {
      this.setState({
        scroll: window.scrollY
      })
    });
  }

  cambiaIdioma (e) {
    window.H5_loading.show();
    this.setState({lenguaje: e})
    if(e==="IN"){
      localStorage.setItem('idioma', e)
      this.props.cambiaIn()
    }else{
      localStorage.removeItem('idioma')
      this.props.cambiaEs()
    }
    window.H5_loading.hide();
  }
  

  render () {
    return (
      <div>
        <Element name="inicio"></Element>
        <a href={window.whats} target="new" className="whatsapp"><img src="img/r_whats-01.svg" alt="Whatsapp" title="Whatsapp"/></a>
        
        {
          this.state.scroll>200 ?
            <Ir to="inicio" activeClass="active" spy={true} smooth={true} duration={1000}>
              <img src="img/top.svg" className="subir grises" alt="Subir" title="Subir"/>
            </Ir>
          :
            null
        }
        <div className="menu_f">
          <div className="menu_back_f">
            <div className="max_width flex_menu">
              {
                this.state.scroll<200 ?
                  <img src="img/logo_b.svg" className="logo_menu" alt="Aerospace Group" title="Aerospace Group"/>
                :
                  <div className="sin_logo">
                    <div className="idioma_flex">
                      <img src="img/idioma_es.svg" className="idioma grises" alt="Español" title="Español" onClick={this.cambiaIdioma.bind(this, "ES")}/>
                      <img src="img/idioma_en.svg" className="idioma grises" alt="English" title="English" onClick={this.cambiaIdioma.bind(this, "IN")}/>
                    </div>
                  </div>
              }
              <div className="menu_redes">  
                {
                  this.props.ver > 0 ?
                    <ul className="regresar_dere">
                      <li>
                        <Link to="inicio" className="cuadro_contacto">{localStorage.idioma ? 'Back' : 'Regresar'}</Link>
                      </li>
                    </ul>
                  :
                    <ul>
                      <li>
                        <Link to="contacto" className="cuadro_contacto">{localStorage.idioma ? 'Contact' : 'Contacto'}</Link>
                      </li>
                      {
                        window._my_proyectos.length > 0 ?
                          <li>
                            <Ir to="proyectos" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'Proyects' : 'Proyectos'}</Ir>
                          </li>
                        :
                          null
                      }
                      <li>
                        <Ir to="cursos" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'Courses' : 'Cursos'}</Ir>
                      </li>
                      <li className="dropdown">
                        <div className="dropbtn">{localStorage.idioma ? 'Interesting' : 'Interesante'}</div>
                        <div class="dropdown-content">
                          <Ir to="perspectivas" activeClass="active" spy={true} smooth={true} duration={500} className="color_negro">{localStorage.idioma ? 'Success stories' : 'Casos de exito'}</Ir>
                          {
                            window._my_perspectivas.length > 0 ?
                              <Ir to="perspectivas" activeClass="active" spy={true} smooth={true} duration={500} className="color_negro">{localStorage.idioma ? 'Industry Insights' : 'Perspectivas para PYMES'}</Ir>
                            :
                              null
                          }
                        </div>
                      </li>
                      <li>
                        <Ir to="services" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'Services' : 'Servicios'}</Ir>
                      </li>
                      <li>
                        <Ir to="about" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'About us' : 'Acerca de nosotros'}</Ir>
                      </li>
                      <li>
                        <Ir to="inicio" activeClass="active" spy={true} smooth={true} duration={500}><img src="img/home-01.svg" className="tama_home grises" alt={localStorage.idioma ? 'Home' : 'Inicio'} title={localStorage.idioma ? 'Home' : 'Inicio'}/></Ir>
                      </li>
                    </ul>
                }
                {
                  window._my_redes.length>0 ?
                    <div className="redes">
                      {
                        window._my_redes.map((item, i) =>
                          <a href={item.link} target="new"><img src={item.img} alt={item.nombre} title={item.nombre} className="grises"/></a>
                        )
                      }
                    </div>
                  :
                    null
                }
                {
                    this.props.ver > 0 ?
                      <Link to="inicio" className="quita_link"><img src="img/regresar.svg" alt={localStorage.idioma ? 'Back' : 'Regresar'} title={localStorage.idioma ? 'Back' : 'Regresar'} className="menu_res" height="25"/></Link>
                    :
                      <img src={"img/"+this.state.verIco} alt={localStorage.idioma ? 'Menu' : 'Menú'} title={localStorage.idioma ? 'Menu' : 'Menú'} className="menu_res" height="25" onClick={this.verMenu}/>
                }
              </div>
            </div>
          </div>
          <div className="menu_r" id={this.state.verMenu}>
            <div className="overflow">
              <img src="img/logo_b.svg" className="logo_menu2 m_bot" alt="Aerospace Group" title="Aerospace Group"/>
              <ul>
                <li>
                  <Ir to="inicio" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'Home' : 'Inicio'}</Ir>
                </li>
                <li>
                  <Ir to="about" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'About us' : 'Acerca de nosotros'}</Ir>
                </li>
                <li>
                  <Ir to="services" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'Services' : 'Servicios'}</Ir>
                </li>
                <li>
                  <Accordion allowMultiple={false}>
                    <AccordionItem title={localStorage.idioma ? 'Interesting' : 'Interesante'} className="dt">
                      <div id="cl"><br/></div>
                      <div>
                        <Ir to="perspectivas" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'Success stories' : 'Casos de exito'}</Ir>
                        <div id="cl"><br/></div>
                      </div>
                      {
                        window._my_perspectivas.length > 0 ?
                          <div>
                            <Ir to="perspectivas" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'Industry Insights' : 'Perspectivas para PYMES'}</Ir>
                            <div id="cl"><br/></div>
                          </div>
                        :
                          null
                      }
                      {
                        window._my_proyectos.length > 0 ?
                          <div>
                            <Ir to="proyectos" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'Proyects' : 'Proyectos'}</Ir>
                            <div id="cl"><br/></div>
                          </div>
                        :
                          null
                      }
                    </AccordionItem>
                  </Accordion>
                </li>
                <li>
                  <Ir to="cursos" activeClass="active" spy={true} smooth={true} duration={500}>{localStorage.idioma ? 'Courses' : 'Cursos'}</Ir>
                </li>
                <li>
                  <Link to="contacto">{localStorage.idioma ? 'Contact' : 'Contacto'}</Link>
                </li>
              </ul>
              <div id="cl"><br/><br/></div>
            </div>
          </div>
          <div className="menu_cerrar" id={this.state.verMenu} onClick={this.verMenu}></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lenguaje: state.lenguaje
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    cambiaEs: () => dispatch(Es()),
    cambiaIn: () => dispatch(In()),
  }
}

const Liga = Menu;
export default connect(mapStateToProps,mapDispatchToProps)(Liga);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const hoy = new Date(); 
const ano = hoy.getFullYear();

class Pie extends Component {
  render () {
    return (
      <div className="bg_blanco pie">
      	<div className="max_width padding_pie at_c">
            <div className="pa_flex_pie imas">
              <div className="de_flex_3">
                <img src="img/logo.svg" alt="Aerospace Group" title="Aerospace Group" className="pie_logo"/>
              </div>
              <div className="de_flex_3 fz-4">
                <Link to="contacto" className="boton_con">{localStorage.idioma ? 'Schedule a meeting' : 'Agenda una reunión'}</Link>
                <div id="cl"><br/><br/></div>
                {localStorage.idioma ? 'Copyright' : 'Todos los derechos reservados'} © {ano}
              </div>
              {
                window._my_redes.length>0 ?
                  <div className="de_flex_3">
                    <div className="flex_redes">
                      {
                        window._my_redes.map((item, i) =>
                          <a href={item.link} target="new" className="de_flex_redes"><img src={item.img} alt={item.nombre} title={item.nombre} className="grises"/></a>
                        )
                      }
                    </div>
                  </div>
                :
                  null
              }
            </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lenguaje: state.lenguaje
  }
};

const Liga = Pie;
export default connect(mapStateToProps)(Liga);
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Inicio from './components/Inicio'
import Pie from './components/Pie'
import Contacto from './components/Contacto'
import Perspectivas from './components/Perspectivas'

class App extends Component {
  render () {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Inicio} />
          {/*
          <Route exact path={window.path+"/panel"} component={Panel} />
          <Route exact path={window.path+"/ver_solicitud/:id"} component={VerSolicitud} />
          */}
          <Route path={window.path+"/contacto"} component={Contacto} />
          <Route path={window.path+"/perspectivas"} component={Perspectivas} />
          <Route component={Inicio} />
        </Switch>
        <div id="cl"></div>
        <Pie />
      </Router>
    )
  }
}

export default App;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import Modal from 'react-bootstrap/Modal';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import { connect } from 'react-redux';

import Es from './../redux/actionEs';
import In from './../redux/actionIn';

import Menu from './Menu'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Inicio extends Component {
  items = window._my_integrantes

  constructor() {
    super();
    this.state = {
      lenguaje: '',
      show: false,
      ver: '',

      galleryItems: this.items.map((item, i) => {
        return(
          <a href={item.link} target="_new" className="caja_miembros_car" key={i}>
            <img src={item.img} alt={item.nombre} className="foto_miembros_car" title={item.nombre}/>
            <div className="titulo_miembros">
              {item.nombre}
            </div>
            {item.puesto}
            {/*
              item.redes.length>0 ?
                <div className="redes_miembros">
                  {
                    item.redes.map((red, n) =>
                      <a href={red.link} target="_new" key={n}>
                        <img src={red.imagen} alt={red.nombre} title={red.nombre}/>
                      </a>
                    )
                  }
                </div>
              :
                null
            */}
          </a>
        )
      }),
      galleryItemsIn: this.items.map((item, i) => {
        return(
          <a href={item.link} target="_new" className="caja_miembros_car" key={i}>
            <img src={item.img} alt={item.nombre} className="foto_miembros_car" title={item.nombre}/>
            <div className="titulo_miembros">
              {item.nombre}
            </div>
            {item.puestoIN}
            {/*
              item.redes.length>0 ?
                <div className="redes_miembros">
                  {
                    item.redes.map((red, n) =>
                      <a href={red.link} target="_new" key={n}>
                        <img src={red.imagen} alt={red.nombre} title={red.nombre}/>
                      </a>
                    )
                  }
                </div>
              :
                null
            */}
          </a>
        )
      }),
    }
  }

  componentWillMount () {
    window.H5_loading.show();
    //window.scrollTo(0, 0)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  cambiaIdioma (e) {
    window.H5_loading.show();
    this.setState({lenguaje: e})
    if(e==="IN"){
      localStorage.setItem('idioma', e)
      this.props.cambiaIn()
    }else{
      localStorage.removeItem('idioma')
      this.props.cambiaEs()
    }
    window.H5_loading.hide();
  }

  handleShow(ver){
    window.H5_loading.show();
    this.setState({ show: true, ver: ver });
    window.H5_loading.hide();
  };
  handleHide(){
    this.setState({ show: false });
  };

  responsive = {
    0: { items: 1 },
    450: { items: 2 },
    700: { items: 3 },
    900: { items: 4 },
  }

  render () {
    return (
      <div>
        <Menu />
        <div className="fondo_1 padding_menu">
          <div className="max_width padding_width minHeight">
            <div className="idioma_flex">
              <img src="img/idioma_es.svg" className="idioma grises" alt="Español" title="Español" onClick={this.cambiaIdioma.bind(this, "ES")}/>
              <img src="img/idioma_en.svg" className="idioma grises" alt="English" title="English" onClick={this.cambiaIdioma.bind(this, "IN")}/>
            </div>
            <div className="flex_ini">
              <div className="de_flex_ini padding_20 azulin fz-1" data-aos="fade-left">
                {
                  localStorage.idioma ? 
                    <b>
                      <h23>We promote the success of great ideas and businesses!</h23>
                    </b>
                  :
                    <b>
                      <h23>¡Impulsamos<br/>el éxito de grandes ideas y negocios!</h23>
                    </b>
                }
                <div id="cl"></div>
                <Link to="contacto" className="boton_con">{localStorage.idioma ? 'Request my diagnosis' : 'Solicitar mi diagnóstico'}</Link>
              </div>
            </div>
          </div>
        </div>

        <Element name="about">
          <div className="bg_blanco">
            <div className="max_width padding_width_grande">
              <img src="img/about.jpg" className="img_libro borde_blanco" alt="Aerospace" title="Aerospace" data-aos="fade-right"/>
              <div className="texto_libro">
                <div className="linea bg_azulin"></div>
                <center><h8>A+ Group Consulting</h8></center>
                <div className="linea bg_azulin"></div>
                <br/>
                <div className="at_j">
                  {
                    localStorage.idioma ? 
                      <div>
                        A+ Group Consulting, is able to provide a range of quality services for national companies, which will enable them operate globally and to transition smoothly into the international aerospace community. 
                        <br/><br/>
                        We are a multidisciplinary team, passionate about the increasing aerospace industry. Together we have +20 years working experience in regional and international projects. 
                        <br/><br/>
                        It is our target to create a close corporate partnership between customers and suppliers with a single point of contact following the applicable regulations for proper decision-making. 
                        <br/><br/>
                        We are conveniently located between the Mexican region Bajio and Mexico City and operate from Italy, France and the United States with support of our experts located in aerospace hubs around the world.
                      </div>
                    :
                      <div>
                        Brindamos una diversa selección de servicios destinados a promover el desarrollo y la excelencia operativa de empresas en diferentes sectores a nivel nacional. Nuestro equipo está conformado por profesionales multidisciplinarios con más de treinta años de experiencia en proyectos de gran envergadura, tanto a nivel local como internacional. Con sede en la Ciudad de México y un alcance nacional, contamos con el respaldo de expertos dentro y fuera del país para proporcionar soluciones adaptadas a las necesidades específicas de cada cliente.
                        {/*
                        Nos dedicamos a ofrecer una amplia gama de servicios diseñados para impulsar el crecimiento y la excelencia operativa de tu empresa o emprendimiento de diversos sectores a nivel nacional.
                        <br/><br/>
                        Nuestro equipo, formado por profesionales multidisciplinarios, está profundamente comprometido con la evolución constante de diversas industrias. Acumulamos colectivamente más de tres décadas de experiencia en proyectos y negocios de envergadura, tanto a nivel local como internacional.
                        <br/><br/>
                        Ubicados estratégicamente en la Ciudad de México, operamos con alcance nacional. Contamos con el respaldo de expertos ubicados dentro y fuera del país de diferentes sectores para ofrecer soluciones adaptadas a las necesidades específicas de su empresa.
                        */}
                      </div>
                  }
                </div>
              </div>
              <div id="cl"><br/><br/></div>
              <div className="pa_flex_2">
                <div className="de_flex_2" data-aos="fade-right">
                  <center>
                    <img src="img/mision-01.svg" className="about_flex" alt={localStorage.idioma ? 'Mission' : 'Misión'}/>
                    <div id="cl"><br/></div>
                    <h7>{localStorage.idioma ? 'Mission' : 'Misión'}</h7>
                    <div id="cl"><br/></div>
                    {
                      localStorage.idioma ? 
                        'Give you quick and concrete solutions for your business that want to boost sales or increase operations.'
                      :
                        'Darte soluciones rápidas y específicas para tu negocio con el objetivo de impulsar las ventas y expandir las operaciones.'
                    }
                    {/*Darte soluciones rápidas y concretas para tu negocio que quieran potencializar ventas o aumentar operaciones.*/}
                  </center>
                </div>
                <div className="de_flex_2" data-aos="fade-left">
                  <center>
                    <img src="img/vision-01.svg" className="about_flex" alt={localStorage.idioma ? 'Vision' : 'Visión'}/>
                    <div id="cl"><br/></div>
                    <h7>{localStorage.idioma ? 'Vision' : 'Visión'}</h7>
                    <div id="cl"><br/></div>
                    {
                      localStorage.idioma ? 
                        'A+ Group wants to consolidate itself in Mexico as a consultant for small and medium-sized businesses to develop the national economy.'
                      :
                        'A+ Group Consulting busca posicionarse como una consultora líder en México, especializada en brindar asesoramiento a pequeñas y medianas empresas para contribuir al desarrollo de la economía nacional.'
                    }
                    {/*A+ Group quiere consolidarse en México como consultora de pequeños y medianos empresarios para desarrollar la economía nacional.*/}
                  </center>
                </div>
              </div>
              {
                window._my_integrantes.length>0 ? 
                  <div className="integrantes">
                    <h20>{localStorage.idioma ? 'Members' : 'Integrantes'}</h20>
                    <div id="cl"><br/></div>
                    <AliceCarousel
                      responsive={this.responsive}
                      autoPlayInterval={1000}
                      //autoPlayDirection="rtl"
                      autoPlay={true}
                      fadeOutAnimation={true}
                      mouseTrackingEnabtutoled={false}
                      playButtonEnabled={false}
                      disableAutoPlayOnAction={true}
                      dotsDisabled={false}
                      buttonsDisabled={true}
                      items={localStorage.idioma ? this.state.galleryItemsIn : this.state.galleryItems}
                      //items={this.state.galleryItems}
                      ref={(el) => (this.Carousel = el)}
                    />{/*
                      {
                        window._my_integrantes.map((item, i) =>
                          <div className="caja_miembros_car" key={i}>
                            <img src={item.img} alt={item.nombre} className="foto_miembros_car" title={item.nombre}/>
                            <div className="titulo_miembros">
                              {item.nombre}
                            </div>
                            {localStorage.idioma ? item.puestoIN : item.puesto}
                            {
                              item.redes.length>0 ?
                                <div className="redes_miembros">
                                  {
                                    item.redes.map((red, n) =>
                                      <a href={red.link} target="_new" key={n}>
                                        <img src={red.imagen} alt={red.nombre} title={red.nombre}/>
                                      </a>
                                    )
                                  }
                                </div>
                              :
                                null
                            }
                          </div>
                        )
                      }
                    </AliceCarousel>*/}
                    {/*
                    <div className="flex_miembros">
                      {
                        window._my_integrantes.map((item, i) =>
                          <div className="caja_miembros" key={i} data-aos="fade-down">
                            <img src={item.img} alt={item.nombre} className="foto_miembros" title={item.nombre}/>
                            <div className="titulo_miembros">
                              {item.nombre}
                            </div>
                            {localStorage.idioma ? item.puestoIN : item.puesto}
                            {
                              item.redes.length>0 ?
                                <div className="redes_miembros">
                                  {
                                    item.redes.map((red, n) =>
                                      <a href={red.link} target="_new" key={n}>
                                        <img src={red.imagen} alt={red.nombre} title={red.nombre}/>
                                      </a>
                                    )
                                  }
                                </div>
                              :
                                null
                            }
                          </div>
                        )
                      }
                    </div>
                    */}
                  </div>
                :
                  null
              }
            </div>
          </div>
        </Element>

        <Element name="services">
          <div className="fondo_2">
            <div className="max_width padding_width_grande">
              <center><h9>{localStorage.idioma ? 'Services' : 'Servicios'}</h9></center>
              <div id="cl"><br/><br/></div>
              {
                window._my_servicios.length>0 ?
                  <div className="servicios_new">
                    {
                      window._my_servicios.map((item, i) =>
                        <div className="caja_servicios_new" key={i} data-aos="fade-down">
                          <img src={item.img} alt={localStorage.idioma ? item.nombreIN : item.nombre} title={localStorage.idioma ? item.nombreIN : item.nombre}/>
                          <div className="texto_servicios_new">
                            <div className="titulo_servicios_new">
                              {localStorage.idioma ? item.nombreIN : item.nombre}
                            </div>
                            <div className="at_j">
                              {localStorage.idioma ? item.descripcionIN : item.descripcion}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                :
                  null
              }
              <div id="cl"><br/></div>
              <center><Link to="contacto" className="boton_con">{localStorage.idioma ? 'Request my diagnosis' : 'Solicitar mi diagnóstico'}</Link></center>

              {/*
              <div className="pa_flex_2c centrar_flex">
                <div className="de_flex_2d" data-aos="fade-right">
                  <center><img src="img/ser_1.png" className="iconos_flex" alt={localStorage.idioma ? 'Marketing and Advertising' : 'Marketing y publicidad'} title={localStorage.idioma ? 'Marketing and Advertising' : 'Marketing y publicidad'}/></center>
                </div>
                <div className="de_flex_2dd" data-aos="fade-left">
                  <div className="cuadro_flex">
                    <h7>{localStorage.idioma ? 'Marketing and Advertising' : 'Marketing y publicidad'}</h7>
                    <div id="cl"><br/></div>
                    {
                      localStorage.idioma ? 
                        "We help you identify your client's needs and determine the best way to meet those needs. We design and implement B2C or B2B sales strategies and execute them with your online and traditional advertising campaigns to promote your capabilities through the appropriate channels."
                      :
                        'Te ayudamos a identificar las necesidades de tu cliente y determinar la mejor manera de satisfacer esas necesidades. Te diseñamos e implementamos estrategias de ventas B2C o B2B y ejecutarla con sus campañas publicitarias en línea y tradicionales para promover tus capacidades a través de los canales adecuados.'
                    }
                  </div>
                </div>
              </div>

              <div className="pa_flex_2c centrar_flex f_quita">
                <div className="de_flex_2dd" data-aos="fade-right">
                  <div className="cuadro_flex">
                    <h7>{localStorage.idioma ? 'Technology deployment and engineering' : 'Implementación de tecnología e ingeniería'}</h7>
                    <div id="cl"><br/></div>
                    {
                      localStorage.idioma ? 
                        'We help you with web design problems and IT services to improve your processes. We create and adopt engineering solutions such as CRM, POS to reduce costs and optimize your processes.'
                      :
                        'Te ayudamos con problemas de diseño web y servicios de IT para mejorar sus procesos. Creamos y adoptamos soluciones de ingeniería  como CRM, POS para reducir costos y optimizar tus procesos.'
                    }
                  </div>
                </div>
                <div className="de_flex_2d" data-aos="fade-left">
                  <center><img src="img/ser_2.png" className="iconos_flex" alt={localStorage.idioma ? 'Technology deployment and engineering' : 'Implementación de tecnología e ingeniería'} title={localStorage.idioma ? 'Technology deployment and engineering' : 'Implementación de tecnología e ingeniería'}/></center>
                </div>
              </div>
              <div className="pa_flex_2c centrar_flex f_sal">
                <div className="de_flex_2d" data-aos="fade-right">
                  <center><img src="img/ser_2.png" className="iconos_flex" alt={localStorage.idioma ? 'Marketing and Advertising' : 'Marketing y publicidad'} title={localStorage.idioma ? 'Marketing and Advertising' : 'Marketing y publicidad'}/></center>
                </div>
                <div className="de_flex_2dd" data-aos="fade-left">
                  <div className="cuadro_flex">
                    <h7>{localStorage.idioma ? 'Technology deployment and engineering' : 'Implementación de tecnología e ingeniería'}</h7>
                    <div id="cl"><br/></div>
                    {
                      localStorage.idioma ? 
                        'We help you with web design problems and IT services to improve your processes. We create and adopt engineering solutions such as CRM, POS to reduce costs and optimize your processes.'
                      :
                        'Te ayudamos con problemas de diseño web y servicios de IT para mejorar sus procesos. Creamos y adoptamos soluciones de ingeniería  como CRM, POS para reducir costos y optimizar tus procesos.'
                    }
                  </div>
                </div>
              </div>

              <div className="pa_flex_2c centrar_flex">
                <div className="de_flex_2d" data-aos="fade-right">
                  <center><img src="img/ser_3.png" className="iconos_flex" alt={localStorage.idioma ? 'Foray into the aerospace industry' : 'Incursión en la industria aeroespacial'} title={localStorage.idioma ? 'Incursion into your market' : 'Incursión en tu mercado'}/></center>
                </div>
                <div className="de_flex_2dd" data-aos="fade-left">
                  <div className="cuadro_flex">
                    <h7>{localStorage.idioma ? 'Incursion into your market' : 'Incursión en tu mercado'}</h7>
                    <div id="cl"><br/></div>
                    {
                      localStorage.idioma ? 
                        'Through knowledge and experience in the market, we have built a reliable network of national decision makers so that we can help you access commercial offers and be ready to face the needs of the market in which you work.'
                      :
                        'A través del conocimiento y experiencia en el mercado, hemos construido una red confiable de tomadores de decisiones nacionales para que podamos ayudarte a acceder a ofertas comerciales y estar listo para enfrentar las necesidades del mercado en el que trabajas.'
                    }
                  </div>
                </div>
              </div>

              <div className="pa_flex_2c centrar_flex f_quita">
                <div className="de_flex_2dd" data-aos="fade-right">
                  <div className="cuadro_flex">
                    <h7>{localStorage.idioma ? 'Potentialization of your company' : 'Potencialización de tu empresa'}</h7>
                    <div id="cl"><br/></div>
                    {
                      localStorage.idioma ? 
                        'We provide general consulting to help you overcome a variety of obstacles such as supply chain management, surety bonds, legal support and customer acquisition in the form of a coaching program.'
                      :
                        'Proporcionamos consultoría general para ayudarte a superar una variedad de obstáculos como la gestión de la cadena de suministro, fianzas, apoyo legal y la adquisición de clientes en forma de un programa de coaching.'
                    }
                  </div>
                </div>
                <div className="de_flex_2d" data-aos="fade-left">
                  <center><img src="img/ser_4.png" className="iconos_flex" alt={localStorage.idioma ? 'Potentialization of your company' : 'Potencialización de tu empresa'} title={localStorage.idioma ? 'Potentialization of your company' : 'Potencialización de tu empresa'}/></center>
                </div>
              </div>
              <div className="pa_flex_2c centrar_flex f_sal">
                <div className="de_flex_2d" data-aos="fade-right">
                  <center><img src="img/ser_4.png" className="iconos_flex" alt={localStorage.idioma ? 'Potentialization of your company' : 'Potencialización de tu empresa'} title={localStorage.idioma ? 'Potentialization of your company' : 'Potencialización de tu empresa'}/></center>
                </div>
                <div className="de_flex_2dd" data-aos="fade-left">
                  <div className="cuadro_flex">
                    <h7>{localStorage.idioma ? 'Potentialization of your company' : 'Potencialización de tu empresa'}</h7>
                    <div id="cl"><br/></div>
                    {
                      localStorage.idioma ? 
                        'We provide general consulting to help you overcome a variety of obstacles such as supply chain management, surety bonds, legal support and customer acquisition in the form of a coaching program.'
                      :
                        'Proporcionamos consultoría general para ayudarte a superar una variedad de obstáculos como la gestión de la cadena de suministro, fianzas, apoyo legal y la adquisición de clientes en forma de un programa de coaching.'
                    }
                  </div>
                </div>
              </div>
              */}
            </div>
          </div>
        </Element>
        <div className="bg_blanco">
          <div className="max_width padding_width_grande at_c">
            <center><h19>{localStorage.idioma ? 'Benefits of using consultancy' : 'Beneficios de usar consultoría'}</h19></center>
            <div className="linea bg_azulin"></div>
            <br/>
            <div className="at_j">
              {
                localStorage.idioma ? 
                  'Acquiring consultancy for a company of any size translates into saving time, their growth and stress relief for their leaders. There are many benefits of using consultancy, some of them are enumerated below:'
                :
                  <div>
                    Contratar servicios de consultoría para empresas, ya sean pequeñas, medianas o grandes, puede representar un importante ahorro de tiempo <b>y dinero al mismo tiempo,</b> un impulso para su crecimiento, aliviando la presión sobre sus dirigentes. Los beneficios de contratar una consultoría son numerosos, entre los cuales incluyen:
                    <ul>
                      <li>Aporta valioso conocimiento especializado sobre la industria <b>o tu sector,</b> facilitando la entrada de tu empresa a nuevas <b>oportunidades de negocio y crecimiento de</b> mercados.</li>
                      <li>Nuevas ideas e innovadoras soluciones gracias a la mirada fresca de un consultor <b>capacitado y con experiencia en tu industria.</b></li>
                      <li>Objetividad al identificar y abordar problemas. <b>Para mejorar tu operación y maximizar tus ganancias.</b></li>
                    </ul>
                  </div>
              }
              {/*'Contratar consultoría para pequeñas medianas o grandes empresas puede ser un gran ahorro de tiempo y un estímulo para su crecimiento, además de un alivio de presión para sus dirigentes. Hay muchos beneficios de contratar una consultoría, algunos se enumeran a continuación:'*/}
            </div>
            <div id="cl"><br/></div>
            <div className="pa_flex_2eye">
              <div className="de_flex_2eye">
                <div className="at_j">
                  <h20>{localStorage.idioma ? 'Fresh eyes and ideas' : 'Ojos e ideas nuevas'}</h20>
                  <div id="cl"><br/></div>
                  {
                    localStorage.idioma ? 
                      'It’s easy to become biased when a business has ben run for along time. Even the most productive companies may overlook opportunity areas. Because a consultor works with many companies, they can bring a fresh pair of eyes to provide an objective comparison between them and is able to give a clear reference to the client of their position in the market. This experience also allows consultants to bring innovative ideas and solutions to problems that clients may have never detected on its own.'
                    :
                      'Es fácil caer en sesgos cuando se ha operado un negocio por mucho tiempo e incluso las compañías más productivas pueden pasar por alto áreas de oportunidad. Debido a que un consultor trabaja con muchas compañías, ellos pueden traer ojos nuevos que ayuden a proveer una comparación objetiva y dar un punto de referencia claro en cuanto a la posición de la empresa en el mercado. Esta experiencia también ayuda a los consultores a traer ideas innovadoras y soluciones a problemas que los clientes podrían no haber detectado nunca por sí mismos.'
                  }
                </div>
              </div>
              <div className="de_flex_2eye2">
                <img src="img/ojos.jpg" alt={localStorage.idioma ? 'Fresh eyes and ideas' : 'Ojos e ideas nuevas'} title={localStorage.idioma ? 'Fresh eyes and ideas' : 'Ojos e ideas nuevas'} data-aos="fade-left"/>
              </div>
            </div>
            <div id="cl"><br/></div>
            <div className="at_j">
              <h20>{localStorage.idioma ? 'Expertise' : 'Expertise'}</h20>
              <div id="cl"><br/></div>
              {
                localStorage.idioma ? 
                  'Another great benefit consultants bring to their clients is knowledge that the business may not have on its own. Their abilities and influence help owners knowing: the market in a deeper way, the industry’s perspective and implementation of new technologies. All these expertise helps the business grow and keeps them competitive. Also, they help them to enter new markets that maximizes income.'
                :
                  'Otro gran activo que los consultores brindan a sus clientes es el conocimiento que tal vez no tenga la empresa dentro de sus colaboradores. Sus habilidades expertas e influencia ayudan a conocer de forma más profunda el mercado, las perspectivas de la industria y la implementación de nuevas tecnologías. Todo este expertise potencializa el crecimiento de las empresas, las mantiene competitivas y les ayudan a incursionar en nuevos mercados que maximizan ganancias.'
              }
              <div id="cl"><br/></div>
              <h20>{localStorage.idioma ? 'Objectivity' : 'Objetividad'}</h20>
              <div id="cl"><br/></div>
              {
                localStorage.idioma ? 
                  'Consultants are not emotionally attached to the business (as the majority of their leaders and workers are). Therefore, their objectivity allows them to perceive, define and attack many problems that normally are not attended. This is specially valuable for family-run business where the consultant can create a shared purpose between owners and the administrative team.'
                :
                  'Los consultores no están emocionalmente vinculados a la empresa (como la mayoría de los colaboradores y líderes de la organización). Esto hace que su objetividad les permita percibir, definir y atacar problemas que muchas veces pasan por alto. Este activo es especialmente valioso en empresas familiares donde el consultor puede crear un propósito compartido tanto por dueños como por el equipo administrativo.'
              }
            </div>
          </div>
        </div>
        {
          window._my_perspectivas.length > 0 ?
            <Element name="perspectivas">
              <div className="fondo_3"><div className="fondo_oscuro">
                <div className="max_width padding_width_grande">
                  <center><h9>{localStorage.idioma ? 'Industry Insights' : 'Perspectivas para PYMES'}</h9></center>
                  <div id="cl"><br/><br/></div>
                  {
                    window._my_perspectivas.slice(0, 2).map((item, i) => 
                      <div className="perspectivas" key={i}>
                        <h17>{localStorage.idioma ? item.nombreIN : item.nombre}</h17>
                        <div id="cl"><br/></div>
                        <div className="at_j">
                          {
                            localStorage.idioma ? 
                              item.descripcionIN
                            :
                              item.descripcion
                          }
                        </div>
                        <div id="cl"></div>
                        <a href={item.link} target="new" className="de_nueva"><div className="nueva" title="Ver más">+</div></a>
                        <div id="cl"></div>
                      </div>
                    )
                  }
                  <div id="cl"></div>
                  {
                    window._my_perspectivas.slice(2, 4).map((item, i) => 
                      <div className="perspectivas" key={i}>
                        <h17>{localStorage.idioma ? item.nombreIN : item.nombre}</h17>
                        <div id="cl"><br/></div>
                        <div className="at_j">
                          {
                            localStorage.idioma ? 
                              item.descripcionIN
                            :
                              item.descripcion
                          }
                        </div>
                        <div id="cl"></div>
                        <a href={item.link} target="new" className="de_nueva"><div className="nueva" title="Ver más">+</div></a>
                        <div id="cl"></div>
                      </div>
                    )
                  }
                  <div id="cl"></div>
                  {
                    window._my_perspectivas.length > 4 ?
                      <center><br/><Link to="perspectivas" className="more_articulos">+ {localStorage.idioma ? 'More articles' : 'Más artículos'}</Link></center>
                    :
                      null
                  }
                </div>
              </div></div>
            </Element>
          :
            null
        }
        {
          window._my_proyectos.length > 0 ?
            <div className="bg_blanco">
              <div className="max_width padding_width_grande at_c">
                <Element name="proyectos">
                  <center><h8>{localStorage.idioma ? 'Proyects' : 'Proyectos'}</h8></center>
                  <br/><br/>
                  <div className="pa_flex_4">
                    {
                      window._my_proyectos.map((item, i) =>
                        <div className="de_flex_4" data-aos="fade-down" key={i}>
                          <img src={item.ico} className="puntero rotateMe" alt={localStorage.idioma ? item.nombreIN : item.nombre} title={localStorage.idioma ? item.nombreIN : item.nombre} onClick={this.handleShow.bind(this, item.nombre)}/>
                          <div id="cl"><br/></div>
                          <h7>{localStorage.idioma ? item.nombreIN : item.nombre}</h7>
                        </div>
                      )
                    }
                  </div>
                </Element>
              </div>
            </div>
          :
            null
        }
        {
          window._my_cursos.length > 0 ?
            <Element name="cursos">
              <div className="bg_cursos">
                <div className="max_width padding_width_grande at_c">
                  <div className="titulo_principal_cursos">
                    {localStorage.idioma ? 'Nuestros cursos' : 'Nuestros cursos'}
                  </div>
                  <div className="sub_principal_cursos">
                    {
                      localStorage.idioma ?
                        'Empezamos por la etapa en la que se encuentra tu empresa y te acompañamos hasta los resultados que buscas'
                      :
                        'Empezamos por la etapa en la que se encuentra tu empresa y te acompañamos hasta los resultados que buscas'
                    }
                  </div>
                </div>
                <div className="flex_cursos">
                  {
                    window._my_cursos.map((item, i) =>
                      <div className="de_flex_cursos hvr-push-dos" id={item.color} data-aos="fade-down" key={i}>
                        <div className="titulo_curso">{localStorage.idioma ? item.nombreIN : item.nombre}</div>
                        <div dangerouslySetInnerHTML={{ __html: localStorage.idioma ? item.descripcionIN : item.descripcion }} className="curso_texto"/>
                        <div id="cl"><br/></div>
                        <a href={item.pdf} target="new" className="hvr-push">{localStorage.idioma ? 'See PDF' : 'Ver PDF'}</a>
                      </div>
                    )
                  }
                </div>
              </div>
            </Element>
          :
            null
        }

        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          dialogClassName="modal-90w"
          className="modal_fer"
          aria-labelledby="example-custom-modal-styling-title">

          <Modal.Body className="modal_cuadro" data-aos="fade-down">
            <div className="jh-close" onClick={this.handleHide.bind(this)}>X</div>
            {
              window._my_proyectos.filter(p => p.nombre === this.state.ver).map(item => 
                <div>
                  <img src={item.img} className="img_libro" alt={localStorage.idioma ? item.nombreIN : item.nombre} title={localStorage.idioma ? item.nombreIN : item.nombre} data-aos="fade-right"/>
                  <div className="texto_libro">
                    <div className="linea bg_azulin"></div>
                    <center><h7>{localStorage.idioma ? item.nombreIN : item.nombre}</h7></center>
                    <div className="linea bg_azulin"></div>
                    <br/>
                    <div className="at_j">
                      {
                        localStorage.idioma ? 
                          item.descripcionIN
                        :
                          item.descripcion
                      }
                    </div>
                  </div>
                </div>
              )
            }
            <div id="cl"></div>
            <div className="espaciado_final"></div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lenguaje: state.lenguaje
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    cambiaEs: () => dispatch(Es()),
    cambiaIn: () => dispatch(In()),
  }
}

const Liga = Inicio;
export default connect(mapStateToProps,mapDispatchToProps)(Liga);